<template>
	<div class="p-fluid">
        <div class="p-grid">

            <div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('DECLARATION OF SOURCE AND ORIGIN OF RESOURCES OF THE TRANSACTION')}}</label>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('Please detail Commercial Activity')}}</label>
				<InputText v-model="$store.state.income.orig_activity" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('Please detail the Origin of the Funds')}}</label>
				<InputText v-model="$store.state.income.orig_funds" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('Products and services')}}</label>
				<InputText v-model="$store.state.income.orig_prod_serv" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('Payment method')}}</label>
				<InputText v-model="$store.state.income.orig_payment_method" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('FINANCIAL PROFILE')}}</label>
			</div>
			<div class="p-col-12 p-md-12 p-lg-4">
				<label>{{$t('Your annual income Main activity')}}</label>
			</div>
			<div class="p-col-12 p-md-12 p-lg-8">
				<SelectButton v-model="$store.state.income.income_activity" :options="options" :optionLabel="i18n.locale()"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-4">
				<label>{{$t('Your annual income from other activities')}}</label>
			</div>
			<div class="p-col-12 p-md-12 p-lg-8">
				<SelectButton v-model="$store.state.income.income_other" :options="options" :optionLabel="i18n.locale()"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-12">
				<label>{{$t('REFERENCES: (Provide a commercial reference and a bank reference)')}}</label>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Name or Social reason')}}</label>
				<InputText v-model="$store.state.income.ref1_name" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Activity')}}</label>
				<InputText v-model="$store.state.income.ref1_activity" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Relationship with the clien')}}</label>
				<InputText v-model="$store.state.income.ref1_relationship" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Telephone')}}</label>
				<InputMask v-model="$store.state.income.ref1_phone" mask="+99999 999999"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Name or Social reason')}}</label>
				<InputText v-model="$store.state.income.ref2_name" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Activity')}}</label>
				<InputText v-model="$store.state.income.ref2_activity" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Relationship with the clien')}}</label>
				<InputText v-model="$store.state.income.ref2_relationship" type="text"/>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<label>{{$t('Telephone')}}</label>
				<InputMask v-model="$store.state.income.ref2_phone" mask="+99999 999999"/>
			</div>
        </div>         
	</div>
</template>

<script>

import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
    props: {
		option: {
			type: Array,
			default: null,
		}
	},
    data() {
        return {
            size: '40vw',
            options: this.option,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
    },
    watch: {
	},
    methods: {
    },


}
</script>